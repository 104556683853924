import { useAccount } from './useAuth';

import { useAddonRecipes } from '@/api';
import {
  Pet,
  PetPlan,
  PetPlanRecipeData,
  PetPlanStatus,
  RecipeFull,
  RecipeType,
} from '@/api/types';

export const useRecommendedAddons = (selectedPets?: Pet[] | undefined): RecipeFull[] => {
  const account = useAccount();
  const { data: allRecipes, isSuccess } = useAddonRecipes(account?.id);
  if (!isSuccess) {
    return [];
  }

  const allAddons = allRecipes?.filter((recipe) =>
    [RecipeType.SNACK, RecipeType.SUPPLEMENT].includes(recipe.type)
  );
  const availableRecipes = allAddons || [];
  const inEveryPetPlan = (petPlans: PetPlan[], recipe: RecipeFull): boolean => {
    if (petPlans.length === 0) return true;
    return (
      [RecipeType.SNACK, RecipeType.SUPPLEMENT].includes(recipe.type) &&
      petPlans.every((petPlan) =>
        petPlan.products.some((product) =>
          product.recipes.some((prodRecipe: PetPlanRecipeData) => prodRecipe.id === recipe.id)
        )
      )
    );
  };

  const petList = selectedPets || account.pets;
  const petPlans = petList
    .map((pet) => pet.pet_plan)
    .filter((petPlan) => petPlan.status !== PetPlanStatus.CANCELLED);

  const availableAddons = availableRecipes.filter((recipe) => !inEveryPetPlan(petPlans, recipe));

  return availableAddons;
};
